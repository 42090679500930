import React from 'react';
import { Link } from 'gatsby';
import Layout from '../layout';
import BgVideoTestimonial from "../components/bgVideoTestimonial";

const NotFound = () => {
  return (
    <Layout>
      <div className="text-center">
        <h1>Not found :(</h1>
        <Link to="/">
          <i className="far fa-arrow-alt-circle-left fa-2x" />
          <span style={{ fontSize: '2rem', marginLeft: '1rem' }}>Go to home</span>
        </Link>
      </div>
    </Layout>
  );
};

export default React.memo(NotFound);

<div className="container-fluid position-relative p-0">
    <div className="border-0 text-left rounded-0 text-white">
        <BgVideoTestimonial />
        <div className="card-img-overlay ov">
            <div className="row h-100 p-4 justify-content-center">
                <div className="align-items-start">
                    <span className="p-5 m-1 bg-themeSecondary" />
                    <span className="p-5 m-1 bg-themeSecondary" />
                </div>
                <div className="card col-md-6 p-md-5 p-2 rounded-0 align-self-center  border-0 bg-transparent">
                    {/* <h3 className="card-title text-black">Mediservices Ltd</h3> */}
                    <p className="card-text display-4  text-neutralDark">
                        Since approaching Onai Intelligence to build our website and rebrand our business, we have since
                        seen an increase in our digital ROI.
                    </p>
                    <p className="card-text case-text text-neutralDark">
                        Onai has since extended our website to include other processes as well as their other Digital, IT
                        and SEO services. I would highly recommend Onai to any small business
                    </p>
                    <small className="text-muted">Andile Siziba</small>
                    <small className="card-title text-primary text-uppercase font-weight-bold">
                        {' '}
                        Director of Neurophysiology | MediServices Healthcare
                    </small>
                </div>
            </div>
        </div>
    </div>
</div>
